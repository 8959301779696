import React, { SVGAttributes } from 'react';
import styled from '@emotion/styled';

const Root = styled.svg`
  height: 60px;
  width: 60px;
  fill: green;
  padding: 4px;
`;

function Etap3(props: SVGAttributes<SVGElement>) {
  return (
    <Root
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 59.8 60"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <path
          d="M59,12c-3.8-3.7-7.5-7.5-11.2-11.2c-0.3-0.3-0.7-0.5-1-0.8C37.4,0,28,0,18.6,0c-0.7,0.3-1.5,0.5-2.2,0.9
		c-1.6,1.1-2.4,2.6-2.4,4.6c0,7.4,0,14.8,0,22.3c0,0.2,0,0.5,0,0.7c-2.3,0.3-4.4,1-6.4,2.2C2,34-1,40.7,0.3,47.1
		c1.3,6.6,6.4,11.6,13,12.7c0.9,0.1,1.8,0.2,2.7,0.2c12.7,0,25.4,0,38.1,0c3.3,0,5.6-2.2,5.6-5.6c0-13.5,0-27,0-40.5
		C59.8,13.1,59.6,12.5,59,12z M48.1,6.3c1.8,1.8,3.6,3.6,5.4,5.4c-1.7,0-3.5,0-5.4,0C48.1,9.8,48.1,8,48.1,6.3z M15.8,56.5
		C9,56.5,3.5,50.9,3.5,44.1c0-6.8,5.5-12.3,12.3-12.3c6.8,0,12.3,5.6,12.3,12.4C28.1,51,22.6,56.5,15.8,56.5z M56.3,15.9
		c0,12.8,0,25.7,0,38.5c0,1.4-0.7,2.1-2.1,2.1c-9.3,0-18.5,0-27.8,0c-0.2,0-0.3,0-0.7,0c1.7-1.4,3-3,4-4.8c1-1.8,1.6-3.7,1.8-5.8
		c0.2,0,0.4,0,0.7,0c5.7,0,11.5,0,17.2,0c0.3,0,0.6,0,0.8-0.1c0.8-0.2,1.4-1,1.3-1.8c-0.1-0.9-0.8-1.6-1.7-1.6c-0.9,0-1.8,0-2.6,0
		c-5,0-10,0-14.9,0c-0.2,0-0.5,0-0.8,0c-0.3-2.5-1.1-4.8-2.6-7c0.3,0,0.5,0,0.8,0c6.6,0,13.1,0,19.7,0c0.3,0,0.5,0,0.8,0
		c0.9-0.2,1.5-1,1.4-1.9c-0.1-0.9-0.8-1.6-1.7-1.6c-1.5,0-3,0-4.5,0c-6.3,0-12.7,0-19,0c-0.4,0-0.8-0.1-1-0.4
		c-2.3-1.6-4.5-2.6-7.7-3.1c0-0.2,0-0.4,0-0.6c0-7.4,0-14.8,0-22.3c0-1.4,0.7-2.1,2-2.1c8.1,0,16.2,0,24.3,0c0.2,0,0.3,0,0.6,0
		c0,0.2,0,0.4,0,0.6c0,3,0,6,0,9c0,1.4,0.7,2,2.1,2c3,0,6,0,9,0c0.2,0,0.4,0,0.7,0C56.3,15.5,56.3,15.7,56.3,15.9z"
        />
        <path
          d="M49.6,21.3c-8,0-16,0-24.1,0c-0.5,0-1.1,0-1.6,0c-0.8,0-1.3,0.4-1.6,1.1c-0.5,1.2,0.4,2.4,1.8,2.4c4.3,0,8.5,0,12.8,0
		c4.2,0,8.5,0,12.7,0c0.8,0,1.4-0.3,1.8-1.1C52,22.6,51.1,21.3,49.6,21.3z"
        />
        <path
          d="M20.2,39.9c-2.1,2.1-4.3,4.2-6.4,6.4c-0.1,0.1-0.3,0.3-0.5,0.5c-0.8-0.8-1.5-1.6-2.2-2.3c-0.7-0.7-1.8-0.7-2.5,0
		c-0.7,0.7-0.7,1.8,0,2.5c1.1,1.2,2.3,2.3,3.5,3.5c0.7,0.7,1.7,0.7,2.5,0c0.1-0.1,0.1-0.1,0.2-0.2c2.6-2.6,5.2-5.2,7.8-7.8
		c0.5-0.5,0.8-1.1,0.6-1.8C22.9,39.2,21.3,38.8,20.2,39.9z"
        />
      </g>
    </Root>
  );
}

export default Etap3;
