import React, { SVGAttributes } from 'react';
import styled from '@emotion/styled';

const Root = styled.svg`
  height: 60px;
  width: 60px;
  fill: green;
  padding: 4px;
`;

function Etap2(props: SVGAttributes<SVGElement>) {
  return (
    <Root
      version="1.1"
      id="Warstwa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 58.6 60"
      xmlSpace="preserve"
      {...props}
    >
      <g>
        <path
          d="M46.1,47.1c-0.3-0.3-0.4-0.6-0.1-1c2.2-3.8,3-7.9,2.5-12.3C47,22,34.9,14.3,23.5,17.9c-9.4,3-15,12.4-13.2,22.2
		c1.9,10.3,12.3,17.3,22.6,15.3c2.4-0.5,4.6-1.3,6.7-2.6c0.2,0.2,0.4,0.4,0.6,0.5c1.8,1.8,3.6,3.6,5.4,5.4c1,1,2.2,1.5,3.6,1.3
		c1.7-0.2,3-1.2,3.6-2.8c0.6-1.7,0.2-3.2-1.1-4.5C49.8,50.8,48,48.9,46.1,47.1z M29.3,52.5c-8.9,0-16.1-7.2-16.1-16.1
		c0-8.9,7.2-16.1,16.1-16.1c8.9,0,16.2,7.2,16.1,16.1C45.4,45.3,38.2,52.5,29.3,52.5z M49.4,56.5c-0.4,0.4-1,0.5-1.4,0
		c-1.9-1.9-3.8-3.8-5.7-5.7c0.5-0.4,1-0.9,1.6-1.4c0,0,0.1,0,0.2,0.1c1.7,1.7,3.5,3.5,5.2,5.2c0.1,0.1,0.2,0.2,0.2,0.3
		C49.9,55.5,49.8,56.1,49.4,56.5z"
        />
        <path
          d="M53.5,14.1c-0.2-0.1-0.4-0.3-0.6-0.4C57,9.7,55.8,4.5,53,2c-3.1-2.8-7.9-2.7-10.8,0.3c-2.7,2.8-3.4,7.7,0.4,11.3
		c-0.7,0.5-1.5,1-2.2,1.6c-0.9,0.8-1,1.6-0.4,2.4c0.7,0.8,1.6,0.8,2.5,0c0.4-0.3,0.8-0.7,1.2-0.9c5-3.1,11.4,0.4,11.7,6.3
		c0,1.2,0.6,1.8,1.7,1.8c1,0,1.6-0.7,1.6-1.9C58.5,19.1,56.7,16.2,53.5,14.1z M47.7,12.4c-2.5,0-4.6-2.1-4.6-4.6
		c0-2.5,2.1-4.6,4.6-4.6c2.5,0,4.6,2,4.6,4.6C52.4,10.3,50.3,12.4,47.7,12.4z"
        />
        <path
          d="M16,17.5c1,0.9,1.9,0.9,2.6,0.1c0.7-0.8,0.6-1.7-0.5-2.6c-0.6-0.5-1.3-0.9-2-1.5c3.8-3.6,3.1-8.6,0.4-11.3
		C13.6-0.5,9-0.8,5.9,1.8C4.3,3.1,3.3,4.9,3.1,7c-0.3,2.7,0.7,4.9,2.6,6.6c0,0.1,0,0.1-0.1,0.1c-0.1,0.1-0.3,0.2-0.4,0.3
		c-3.3,2.2-5.2,5.2-5.3,9.2c0,0.9,0.5,1.5,1.3,1.6c1.1,0.2,1.9-0.5,1.9-1.8c0.1-2.1,0.9-3.9,2.4-5.4C8.5,14.9,12.9,14.9,16,17.5z
		 M6.3,7.7c0-2.5,2.1-4.5,4.6-4.5c2.5,0,4.6,2.1,4.6,4.6c0,2.5-2.1,4.6-4.6,4.6C8.3,12.4,6.3,10.3,6.3,7.7z"
        />
        <path
          d="M34.6,35.6c1.6-1.5,2.6-3.4,2.6-5.7c0-2.3-0.9-4.3-2.6-5.9c-3.1-2.8-7.6-2.6-10.6,0.2c-2.5,2.4-3.8,7.4,0.3,11.5
		C24,35.9,23.9,36,23.7,36c-3.3,2.1-5.2,5.1-5.3,9.1c0,1.1,0.6,1.7,1.6,1.7c1,0,1.6-0.6,1.7-1.7c0.1-0.8,0.1-1.5,0.4-2.3
		c1.1-3.5,4.7-5.8,8.3-5.3c3.7,0.5,6.5,3.6,6.7,7.4c0.1,1.2,0.6,1.9,1.7,1.8c1,0,1.6-0.7,1.5-1.9c-0.1-2.5-0.9-4.7-2.5-6.6
		C36.7,37.4,35.6,36.6,34.6,35.6z M29.3,34.4c-2.5,0-4.6-2.1-4.6-4.6c0-2.5,2.1-4.6,4.6-4.6c2.5,0,4.6,2.1,4.5,4.6
		C33.9,32.4,31.8,34.5,29.3,34.4z"
        />
      </g>
    </Root>
  );
}

export default Etap2;
